import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-privacidade',
  templateUrl: './privacidade.component.html',
  styleUrls: ['./privacidade.component.scss']
})
export class PrivacidadeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
