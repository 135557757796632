<div class="mt-8">
  <p-card>
    <div class="grid p-6 align-items-center">
      <div class="col-12 xl:col-6 lg:col-6">
        <div class="text-center">
          <img
            src="assets/images/brand.png"
            alt="coston Logo"
            class="w-5"
          />
        </div>
        <h1 class="text-center">Bem vindo ao Coston</h1>
        <ul>
          <li>
            Fique sabendo dos melhores preços a qualquer momento.
          </li>
          <li>Colabore com o app e mantenha os preços atualizados.</li>
          <li>Favorite seus produtos para não perder as alterações de preço.</li>
          <li>Muito mais.</li>
        </ul>
        <div class="flex justify-content-center mt-8">
          <a
            href="https://play.google.com/store/apps/details?id=br.com.coston"
            target="_blank"
            class="mx-2"
          >
            <img
              src="assets/images/google-play-badge.png"
              alt="Google Play Badge"
            />
          </a>
          <!-- <a
            href="https://apps.apple.com/app/"
            target="_blank"
            class="mx-2"
          >
            <img
              src="assets/images/app-store-badge.png"
              alt="App Store Badge"
            />
          </a> -->
        </div>
      </div>
      <div class="col-12 xl:col-6 lg:col-6 mt-4 sm:mt-0">
        <p-galleria
          [value]="images"
          [showItemNavigators]="true"
          [showThumbnails]="false"
          [showIndicators]="true"
          [autoPlay]="true"
          [circular]="true"
        >
          <ng-template pTemplate="item" let-item>
            <img
              [src]="item.itemImageSrc"
              style="width: 55%; display: block; border-radius: 10px"
            />
          </ng-template>
          <ng-template pTemplate="thumbnail" let-item>
            <div class="grid grid-nogutter justify-content-center">
              <img [src]="item.thumbnailImageSrc" style="display: block" />
            </div>
          </ng-template>
        </p-galleria>
      </div>
    </div>
  </p-card>
</div>
