<div class="mt-8">
  <p-card>
    <h2><span>Política Privacidade</span></h2>
    <p>
      <span
        >A sua privacidade é importante para nós. É política do Coston
        respeitar a sua privacidade em relação a qualquer informação sua que
        possamos coletar no site <a routerLink="/">Coston</a>, e outros
        sites que possuímos e operamos.</span
      >
    </p>
    <p>
      <span
        >Solicitamos informações pessoais apenas quando realmente precisamos
        delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais,
        com o seu conhecimento e consentimento. Também informamos por que
        estamos coletando e como será usado.</span
      >
    </p>
    <p>
      <span
        >Apenas retemos as informações coletadas pelo tempo necessário para
        fornecer o serviço solicitado. Quando armazenamos dados, protegemos
        dentro de meios comercialmente aceitáveis para evitar perdas e roubos,
        bem como acesso, divulgação, cópia, uso ou modificação não
        autorizados.</span
      >
    </p>
    <p>
      <span
        >Não compartilhamos informações de identificação pessoal publicamente ou
        com terceiros, exceto quando exigido por lei.</span
      >
    </p>
    <p>
      <span
        >O nosso site pode ter links para sites externos que não são operados
        por nós. Esteja ciente de que não temos controle sobre o conteúdo e
        práticas desses sites e não podemos aceitar responsabilidade por suas
        respectivas políticas de privacidade</span
      >
    </p>
    <p>
      <span
        >Você é livre para recusar a nossa solicitação de informações pessoais,
        entendendo que talvez não possamos fornecer alguns dos serviços
        desejados.</span
      >
    </p>
    <p>
      <span
        >O uso continuado de nossos sites e aplicativos será considerado como
        aceitação de nossas práticas em torno de privacidade e informações
        pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do
        usuário e informações pessoais, entre em contacto conosco.</span
      >
    </p>
    <p>
      <span
        ><ul>
          <li>
            <span
              >O serviço Google AdSense que usamos para veicular publicidade usa
              um cookie DoubleClick para veicular anúncios mais relevantes em
              toda a Web e limitar o número de vezes que um determinado anúncio
              é exibido para você.</span
            >
          </li>
          <li>
            <span
              >Para mais informações sobre o Google AdSense, consulte as FAQs
              oficiais sobre privacidade do Google AdSense.</span
            >
          </li>
          <li>
            <span
              >Utilizamos anúncios para compensar os custos de funcionamento
              deste site e fornecer financiamento para futuros desenvolvimentos.
              Os cookies de publicidade comportamental usados por este site
              foram projetados para garantir que você forneça os anúncios mais
              relevantes sempre que possível, rastreando anonimamente seus
              interesses e apresentando coisas semelhantes que possam ser do seu
              interesse.</span
            >
          </li>
          <li>
            <span
              >Vários parceiros anunciam em nosso nome e os cookies de
              rastreamento de afiliados simplesmente nos permitem ver se nossos
              clientes acessaram o site através de um dos sites de nossos
              parceiros, para que possamos creditá-los adequadamente e, quando
              aplicável, permitir que nossos parceiros afiliados ofereçam
              qualquer promoção que pode fornecê-lo para fazer uma compra.</span
            >
          </li>
        </ul>
        <p><br /></p
      ></span>
    </p>
    <h3><span>Compromisso do Usuário</span></h3>
    <p>
      <span
        >O usuário se compromete a fazer uso adequado dos conteúdos e da
        informação que o Coston oferece no site e com caráter enunciativo,
        mas não limitativo:</span
      >
    </p>
    <ul>
      <li>
        <span
          >A) Não se envolver em atividades que sejam ilegais ou contrárias à
          boa fé a à ordem pública;</span
        >
      </li>
      <li>
        <span
          >B) Não difundir propaganda ou conteúdo de natureza racista,
          xenofóbica, ou azar, qualquer tipo de pornografia ilegal, de apologia
          ao terrorismo ou contra os direitos humanos;</span
        >
      </li>
      <li>
        <span
          >C) Não causar danos aos sistemas físicos (hardwares) e lógicos
          (softwares) do Coston, de seus fornecedores ou terceiros, para
          introduzir ou disseminar vírus informáticos ou quaisquer outros
          sistemas de hardware ou software que sejam capazes de causar danos
          anteriormente mencionados.</span
        >
      </li>
    </ul>
    <h3><span>Retenção e Exclusão dos seus Dados Pessoais</span></h3>
    <p>
      <span
        >Nós trataremos seus dados pessoais com elevados níveis de proteção e
        garantindo sua privacidade, durante todo o período que você for nosso
        cliente, navegando em nossas plataformas e utilizando nossos serviços,
        ou potencial cliente com quem dividimos nossas ofertas de produtos e
        serviços.</span
      >
    </p>
    <p>
      <span
        >Quando aplicável, e mesmo se você optar por excluir seus dados pessoais
        da nossa base de cadastro, a Coston poderá reter alguns ou todos os
        seus dados pessoais por períodos adicionais para cumprimento de
        obrigações legais ou regulatórias, para o exercício regular de direitos
        da Coston, eventuais ações judiciais, fins de auditoria de diversas
        naturezas, ou outros prazos definidos e fundamentados por bases legais
        que justifiquem a retenção destes dados.</span
      >
    </p>
    <p>
      <span
        >Para solicitar a exclusão dos seus dados, envie um e-mail para
        <a href="mailto:contato@coston.com.br">{{
          "contato@coston.com.br"
        }}</a>
        que iremos atender sua solicitação o mais rápido possível.</span
      >
    </p>
    <h3><span>Alterações da Política de Privacidade</span></h3>
    <p>
      <span
        >Nossa Política de Privacidade está em constante aprimoramento e nossas
        práticas de tratamento de dados pessoais poderão sofrer alterações com a
        inclusão de novas funcionalidades e serviços. Assim, esse Aviso de
        Política de Privacidade pode ser alterado.
      </span>
    </p>
    <p>
      <span
        >Valorizamos a transparência no modo em que tratamos seus dados
        pessoais. Toda vez que alguma condição relevante de nossa Política de
        Privacidade for modificada, essas alterações serão válidas, eficazes e
        vinculantes após a nova versão ser publicada em nosso site. Estas
        alterações serão comunicadas por meio de um dos canais disponibilizados
        por você durante o seu cadastro (e-mail, SMS, WhatsApp e outros).
      </span>
    </p>
    <h3><span>Mais informações</span></h3>
    <p>
      <span
        >Esperemos que esteja esclarecido e, como mencionado anteriormente, se
        houver algo que você não tem certeza se precisa ou não, geralmente é
        mais seguro deixar os cookies ativados, caso interaja com um dos
        recursos que você usa em nosso site.</span
      >
    </p>
    <p>
      <span>Esta política é efetiva a partir de 01 Janeiro 2024</span>
    </p>
  </p-card>
</div>
